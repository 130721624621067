<template>
    <div class="coming-soon">
        <img src="~@/assets/images/coming_soon.png" alt="开发中…" class="img" />
        <span class="text">开发中，敬请期待…</span>
    </div>
</template>

<script>
export default {
    name: 'urgentRepairs'
};
</script>

<style scoped lang="less">
.coming-soon {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .img {
        width: 30%;
    }

    .text {
        font-size: 14px;
        margin: 30px;
    }
}
</style>
